import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import * as api from '@fwk-client/utils/api';
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

interface ListDBsInput {
}

export function useListDBs(props:ListDBsInput, {emit}:any) { 
  const app = getApp();

  var isListDBLoading:Ref<boolean> = ref(false);
  var listDBs:Ref<any[]> = ref([]);
  var currentDB:Ref<string|null> = ref(null);

  const updateListDBs = () => {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: app
    }
    isListDBLoading.value = true;
    api.getAPI('/api/admin/database/configs', options).then((response:any) => {
      if(response.configs) {  
        listDBs.value = response.configs;
        currentDB.value = response.current;
      }
      else {
        listDBs.value = [];
        currentDB.value = null;
      }
      isListDBLoading.value = false;
    });
  }
  updateListDBs();

  const listDBsOptionLabel = (option:any) => {
    return option.title;
  }

  const listDBsReduce = (dbConfig:any) => {
    return dbConfig.title;
  }

  return {
    listDBsOptionLabel,
    listDBsReduce,
    isListDBLoading,
    listDBs,
    currentDB
  }
  
}