<template>
  
  <div class="ibox">
    <div class="ibox-title">
      <h2>Cleanup Site Statics</h2>
    </div>
    <div :class="'ibox-content p-md'+ (panelLoading ? ' sk-loading' : '')">

      <div v-if="panelLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <form role="form" @submit="onFormSubmit" name="actionsSiteForm">
        <div class="form-group row">
          <div class="col">
            <v-select :options="listDBs" :getOptionLabel="listDBsOptionLabel" :reduce="listDBsReduce" v-model="form.targetDB" placeholder="Select an origin DB" @keypress.enter.native.prevent="">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!form.targetDB"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </div>
          <div class="col">
            <v-select :options="listSites" v-model="form.site" :getOptionLabel="option => option.name" placeholder="Select a site" @keypress.enter.native.prevent="">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!form.site"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <button class="btn btn-primary ladda-button cleanup-statics" data-style="zoom-in" type="submit">Cleanup Statics</button>
          </div>
        </div>
      </form>

      

    </div>
  </div>
        
</template>

<style scoped>
  
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as socket from '@fwk-client/utils/socket';
import * as Ladda from 'ladda';
import { useListDBs } from '@root/src/client/composables/admin/useListDBs';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';

import { useSiteAdmin } from '../../../../composables/useSiteAdmin';
import { useCmsAdmin } from '../../../../composables/useCmsAdmin';

export default defineComponent({
    props: {
        
    },
    components: {
        SwitchCheck
    },
    setup(props, context) {
        const app = getApp();
        const $router = useRouter();
        const $store = useStore();
        const { listDBs, isListDBLoading, listDBsReduce, listDBsOptionLabel } = useListDBs(props, context);
        const { getListSites } = useSiteAdmin(props, context);
        const { callCmsAdmin } = useCmsAdmin(props, context);

        const form:any = reactive({
          site:"",
          targetDB:"",
        });

        var cleanupStaticsLadda:Ladda.LaddaButton|null = null;

        onMounted(() => {
          var cleanupStaticsButton:HTMLButtonElement|null = document.querySelector( 'form[name=actionsSiteForm] button.ladda-button.cleanup-statics' );
          cleanupStaticsLadda = Ladda.create(cleanupStaticsButton!);
        })

        var formLoading:boolean = false;
        const onFormSubmit = (evt:Event) => {
          evt.preventDefault();

          var input = {
            targetDB : form.targetDB,
          }

          cleanupStaticsLadda!.start();
          formLoading = true;

          var response = callCmsAdmin('/site/'+form.site._id+'/routes/cleanup-statics', input).then((response:any) => {
            cleanupStaticsLadda!.stop();
            formLoading = false;
          }).catch((response:any) => {
            cleanupStaticsLadda!.stop();
            formLoading = false;
          })
        }

        const panelLoading = computed(() => {
            return formLoading || isListDBLoading.value;
        })

        const listSites:Ref<any[]> = ref([]);

        watch(
          () => form.targetDB,
          (val:any, oldVal:any) => {
            if(val) {
              getListSites({
                targetDB: val
              }).then((sites:any[]) => {
                  listSites.value = sites.map((site:any) => {
                    return site.site;
                })
              }) 
            }
            else {
              listSites.value = [];
            }  
          },
          { deep: false }
        )

        return {
          panelLoading,
          listSites,
          listDBsOptionLabel,
          listDBsReduce,
          onFormSubmit,
          listDBs,
          form
        }
  }
})
</script>