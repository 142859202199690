<template>
  <section id="content" ref="cmsContent">
    <PageTitle :title="$t('cms.admin.title')"></PageTitle>
    <div class="wrapper wrapper-content animated fadeInRight">

      <div class="row generate-success">
        <div class="col-lg-12">
          <ListSites></ListSites>
        </div>
      </div>

      <div class="row generate-success">
        <div class="col-lg-12">
          <CopySite></CopySite>
        </div>
      </div>

      <div class="row generate-success">
        <div class="col-lg-12">
          <CleanUpStatics></CleanUpStatics>
        </div>
      </div>

    </div>
  </section>
</template>

<script lang="ts">
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';

import PageTitle from '@root/src/client/components/panels/PageTitle.vue';

import ListSites from '../../panels/admin/sites/ListSites.vue';
import CopySite from '../../panels/admin/sites/Copy.vue';
import CleanUpStatics from '../../panels/admin/sites/CleanUpStatics.vue';


@Component({
  components: {
    PageTitle,
    ListSites,
    CopySite,
    CleanUpStatics
  }
})
export default class Cms extends mixins(GenericPage) {
  
}
</script>