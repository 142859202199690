var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('form',{attrs:{"role":"form","name":"site-form"},on:{"submit":_vm.onFormSubmit}},[_c('TextField',{ref:"updateName",attrs:{"value":_vm.siteForm.name,"id":"updateName","label":_vm.$t('cms.site.info.name'),"placeholder":_vm.$t('cms.site.info.name_placeholder'),"labelAsColumn":true,"required":true},on:{"update:value":function($event){return _vm.$set(_vm.siteForm, "name", $event)}}}),_vm._v(" "),_c('TextField',{ref:"updateCode",attrs:{"value":_vm.siteForm.code,"id":"updateCode","label":_vm.$t('cms.site.info.code'),"placeholder":_vm.$t('cms.site.info.code_placeholder'),"labelAsColumn":true,"required":true},on:{"update:value":function($event){return _vm.$set(_vm.siteForm, "code", $event)}}}),_vm._v(" "),_c('ListTextFields',{ref:"updateDomains",attrs:{"value":_vm.siteForm.domains,"id":"updateDomains","label":_vm.$t('cms.site.info.domains'),"placeholder":_vm.$t('cms.site.info.domains_placeholder'),"labelAsColumn":true,"required":true},on:{"update:value":function($event){return _vm.$set(_vm.siteForm, "domains", $event)}}}),_vm._v(" "),_c('Select',{attrs:{"label":_vm.$t('cms.site.info.company'),"placeholder":_vm.$t('cms.site.info.company_placeholder'),"selectOptions":{
        options: _vm.listCompanies,
        getOptionLabel : option => option.name
      },"value":_vm.siteForm.company,"labelAsColumn":true,"required":true},on:{"update:value":function($event){return _vm.$set(_vm.siteForm, "company", $event)}}}),_vm._v(" "),_c('SwitchCheck',{ref:"updateIsActivated",attrs:{"value":_vm.siteForm.isActivated,"id":"updateIsActivated","label":_vm.$t('cms.site.info.isActivated'),"labelAsColumn":true},on:{"update:value":function($event){return _vm.$set(_vm.siteForm, "isActivated", $event)}}}),_vm._v(" "),_c('TextField',{ref:"updateConfiguration",attrs:{"textarea":true,"value":_vm.siteForm.configuration,"id":"updateConfiguration","label":_vm.$t('cms.site.info.configuration'),"placeholder":'JSON configuration',"labelAsColumn":true},on:{"update:value":function($event){return _vm.$set(_vm.siteForm, "configuration", $event)}}}),_vm._v(" "),(_vm.listShops.length > 0)?_c('Select',{attrs:{"label":_vm.$t('cms.site.info.shop'),"placeholder":_vm.$t('cms.site.info.shop_placeholder'),"selectOptions":{
        options: _vm.listShops,
        getOptionLabel : option => option.name
      },"value":_vm.siteForm.shop,"labelAsColumn":true},on:{"update:value":function($event){return _vm.$set(_vm.siteForm, "shop", $event)}}}):_vm._e(),_vm._v(" "),(_vm.feeds.length > 0)?_c('Select',{attrs:{"label":_vm.$t('cms.site.info.feeds'),"placeholder":_vm.$t('cms.site.info.feeds_placeholder'),"selectOptions":{
        multiple: true,
        options: _vm.availableFeeds,
        getOptionLabel : option => option.name
      },"value":_vm.siteForm.feeds,"labelAsColumn":true},on:{"update:value":function($event){return _vm.$set(_vm.siteForm, "feeds", $event)}}}):_vm._e(),_vm._v(" "),(_vm.agencies.length > 0)?_c('Select',{attrs:{"label":_vm.$t('cms.site.info.agencies'),"placeholder":_vm.$t('cms.site.info.agencies_placeholder'),"selectOptions":{
        multiple: true,
        options: _vm.availableAgencies,
        getOptionLabel : option => option.name
      },"value":_vm.siteForm.agencies,"labelAsColumn":true},on:{"update:value":function($event){return _vm.$set(_vm.siteForm, "agencies", $event)}}}):_vm._e(),_vm._v(" "),_c('button',{staticClass:"btn btn-primary ladda-button",attrs:{"data-style":"zoom-in","type":"submit"}},[_vm._v(_vm._s(_vm.buttonLabel))])],1),_vm._v(" "),_c('SiteAdminForm',{attrs:{"site":_vm.site}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }