<template>
  <validation-provider ref="validator" :name="id" mode="eager" v-slot="{ errors, classes }" slim>
    <div :class="{...rowCSSClass, ...classes}">
      <label :for="id" :class="{...labelCSSClass}">{{ inputLabel }} <small v-if="required">*</small></label>
      <div :class="{...fieldCSSClass}">
        <div class="input-group mb-1" v-for="(value, index) in input" :key="id+'_'+index">
          <input :id="id+'_'+index" type="text" :placeholder="inputPlaceholder" :class="{...inputCSSClass, 'required':required, ...classes}" v-model="input[index]"/>
          <span class="input-group-append">
            <button type="button" class="btn btn-primary remove" @click="removeItem($event, index)">X</button>
          </span>
        </div>
        <span :class="{...controlCSSClass}" v-if="errors.length > 0">{{ errors[0] }}</span>
        <div v-if="nbItemsToAdd > 0" class="input-group mt-1">
          <input :id="id+'_add'" type="text" :placeholder="addPlaceholder" class="form-control" v-model="addItemValue" />
          <span class="input-group-append">
            <button type="button" class="btn btn-primary add" :disabled="!isItemToAddEnabled" @click="addItem">+</button>
          </span>
        </div>
      </div>
    </div>
    
  </validation-provider>
</template>

<script lang="ts">
import { Component, Watch, Prop } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericInput from '../../mixins/GenericInput.vue';

@Component({
  components : {}
})
export default class ListTextFields extends mixins<GenericInput<string[]>>(GenericInput) {

  @Prop({
    type: String,
    required: false
  }) readonly addLabel!: string

  @Prop({
    type: String,
    required: false
  }) readonly addPlaceholder!: string

  @Prop({
    type: Number,
    required: false,
    default: -1
  }) readonly maxFields!: number

  addItemValue = "";

  get isItemToAddEnabled() {
    if(this.addItemValue == "") {
      return false;
    }
    return true;
  }

  get nbItemsToAdd() {
    if(this.maxFields <= 0) { return 1; }
    return this.maxFields - this.input.length;
  }

  get inputLabel() {
    return (this.label && this.label != "") ? this.label : "";
  }

  get inputPlaceholder() {
    return (this.placeholder && this.placeholder != "") ? this.placeholder : "";
  }

  validate() {
    // @ts-ignore
    return this.$refs.validator.validate();
  }

  addItem(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    this.input.push(this.addItemValue);

    this.addItemValue = '';
  }

  removeItem(evt:Event, index:number) {
    // We prevent submit of the page
    evt.preventDefault();
    // We update the table
    this.input.splice(index, 1);
  }

  @Watch('value')
  onValueChange(val: any, oldVal: any) {
    this.input = val;
    // @ts-ignore
    this.$refs.validator.validate(val);
  }
  
}
</script>