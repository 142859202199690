<template>
  <div class="ibox sites">
    <div class="ibox-title">
      <h2>{{ $t('cms.site.list.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <p v-if="showNumberOfRows">Number of rows: {{numberOfRows}}</p>
    
      <b-table 
          outlined striped
          :items="sites"
          :fields="listFields"
          ref="listItems">
        <template v-slot:cell(select)="row">
          <p-check class="p-default p-curve p-primary" color="success" :value="row.item.site._id" v-model="listItemsSelected"></p-check>
        </template>
        <template v-slot:cell(web)="row">
          <a v-if="row.item.site.web" :href="row.item.site.web" target="_blank">{{row.item.site.web}}</a>
        </template>
        <template v-slot:cell(options)="row">
          <span v-if="isSiteUpdateAllowed">
            <a href="javascript:void(0)" @click="showUpdateModal(row.item.site)">{{$t('cms.site.list.options.update')}}</a>
            /
          </span>
          <span v-if="isSiteRemoveAllowed">
            <a href="javascript:void(0)" @click="confirmRemoveItem(row.item.site)">{{$t('cms.site.list.options.delete')}}</a>
            /
          </span>
          <a href="javascript:void(0)" @click="showSiteRoutesPage(row.item.site)">{{$t('cms.site.list.options.routes')}}</a>
          /
          <a href="javascript:void(0)" @click="showSiteThemePage(row.item.site)">{{$t('cms.site.list.options.theme')}}</a>
          /
          <a href="javascript:void(0)" @click="showSiteUsersPage(row.item.site)">{{$t('cms.site.list.options.users')}}</a>
          /
          <a href="javascript:void(0)" @click="showDeploymentPage(row.item.site)">{{$t('cms.site.list.options.deployment')}}</a>
        </template>
      </b-table>

      <button v-if="isSiteCreateAllowed" class="btn btn-primary" @click="showCreateItemModal()">{{$t('cms.site.list.create-button')}}</button>

      <b-modal size="xl" ref="updateItemModal" :title="$t('cms.site.update.title', {siteName: itemToUpdate.name})" hide-footer lazy>
        <SiteForm :site="itemToUpdate" v-on:site-updated="onItemUpdated" />
      </b-modal>

      <b-modal size="xl" ref="createItemModal" title="Create new site" hide-footer lazy>
        <SiteForm v-on:site-created="onItemCreated" />
      </b-modal>

      <b-modal ref="removeItemModal" 
          hide-header
          @ok="removeItem">
        {{$t('cms.site.list.delete-confirmation', {siteName: itemToRemove.name})}}
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
  
</style>



<script lang="ts">
import { Ref, defineComponent, ComputedRef, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import SiteForm from './SiteForm.vue';
import { languagesTypes } from '@fwk-client/store/types';

import { useSiteAdmin } from '../../../../composables/useSiteAdmin';


export default defineComponent({
  props: {
  },
  components: {
    SiteForm
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { sites, selectedSite, selectSite, isSiteUpdateAllowed, isSiteCreateAllowed, isSiteRemoveAllowed, isListLoading, removeSite } = useSiteAdmin(props, context);

    const listItems:Ref<HTMLElement|null> = ref(null);
    const createItemModal:Ref<HTMLElement|null> = ref(null);
    const removeItemModal:Ref<HTMLElement|null> = ref(null);
    const updateItemModal:Ref<HTMLElement|null> = ref(null);

    const isMultiSelectAllowed:Ref<boolean> = ref(false);
    const showNumberOfRows:Ref<boolean> = ref(false);

    const numberOfRows:Ref<number> = computed(() => {
        return sites.value.length
    })

    const itemToRemove:Ref<any> = ref({
      "company" : {
        "name" : ""
      }
    });
    const itemToUpdate:Ref<any> = ref({
      "company" : {
        "name" : ""
      }
    });

    
    const listLoading:Ref<boolean> = computed(() => {
      return isListLoading.value || removeLoading.value;
    })

    const listItemsSelected:Ref<any[]> = ref([]);

    const listFields = [
      {
        key: "select",
        label: "",
        class: (isMultiSelectAllowed.value ? "" : "hidden"),
      },
      {
        key: "site.name",
        label: ""
      },
      {
        key: "site.company.name",
        label: ""
      },
      {
        key: "options"
      }
    ];

    onMounted(() => {
      listFields[1].label = app.$t('cms.site.list.headers.name') as string;
      listFields[2].label = app.$t('cms.site.list.headers.company') as string;
      listFields[3].label = app.$t('cms.site.list.headers.options') as string;
    })

    const currentLanguageCode:ComputedRef<string> = computed(() => {
      return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
    })

    const onItemCreated = () => {
      // @ts-ignore
      createItemModal.value.hide()
    }

    const onItemUpdated = (item:any) => {
      itemToUpdate.value = item;
    }

    const confirmRemoveItem = (item:any) => {
      itemToRemove.value = item;
      // @ts-ignore
      removeItemModal.value.show()
    }

    const showUpdateModal = (item:any) => {
      itemToUpdate.value = item;
      // @ts-ignore
      updateItemModal.value.show()
    }

    const showCreateItemModal = () => {
      // @ts-ignore
      createItemModal.value.show()
    }

    const showSiteThemePage = (site:any) => {
      // We first should select the feed
      selectSite(site._id).then(() => {
        // We redirect to the feed dedicated page
        $router.push("/"+currentLanguageCode.value+"/cms/theme").catch((err:any) => {});
      })
    }

    const showSiteUsersPage = (site:any) => {
      // We first should select the feed
      selectSite(site._id).then(() => {
        // We redirect to the feed dedicated page
        $router.push("/"+currentLanguageCode.value+"/cms/users").catch((err:any) => {});
      })
    }

    const showSiteRoutesPage = (site:any) => {
      // We first should select the feed
      selectSite(site._id).then(() => {
        // We redirect to the feed dedicated page
        $router.push("/"+currentLanguageCode.value+"/cms/routes").catch((err:any) => {});
      })
    }

    const showDeploymentPage = (site:any) => {
      // We first should select the feed
      selectSite(site._id).then(() => {
        // We redirect to the feed dedicated page
        $router.push("/"+currentLanguageCode.value+"/cms/deployment").catch((err:any) => {});
      })
    }

    const removeLoading:Ref<boolean> = ref(false);
    const removeItem = () => {

      removeLoading.value = true;
      removeSite(itemToRemove.value._id).then((removed:boolean) => {
        // We reset the user to remove
        itemToRemove.value = {};

        removeLoading.value = false;
      })
    }
    return {
      listLoading,
      showNumberOfRows,
      numberOfRows,
      sites,
      listItems,
      listFields,
      listItemsSelected,
      isSiteUpdateAllowed,
      showUpdateModal,
      isSiteRemoveAllowed,
      confirmRemoveItem,
      showSiteRoutesPage,
      showSiteThemePage,
      showSiteUsersPage,
      showDeploymentPage,
      isSiteCreateAllowed,
      showCreateItemModal,
      updateItemModal,
      itemToUpdate,
      onItemUpdated,
      createItemModal,
      onItemCreated,
      removeItemModal,
      removeItem,
      itemToRemove
    }
  }
})
</script>