<template>
  
    <form role="form" @submit="onFormSubmit" name="site-admin-configuration-form">
      <Select
        v-if="availableComponents.length > 0"
        :label="getLocalizedText(labels.listComponents)"
        :placeholder="getLocalizedText(labels.listComponents_placeholder)"
        :selectOptions="{
          multiple: true,
          options: availableComponents
        }"
        v-bind:value.sync="siteAdminConfigurationForm.allowedComponents" 
        :labelAsColumn="true"
      />
      <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{ getLocalizedText(labels.updateButton) }}</button>
    </form>

</template>


<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as Ladda from 'ladda';
import Select from '@fwk-client/components/panels/input/Select.vue';

import { useSiteAdmin } from '../../../../composables/useSiteAdmin';
import { useCmsAdmin } from '../../../../composables/useCmsAdmin';

export default defineComponent({
  props: {
      site: {
        type: Object,
        required: true
      }
  },
  components: {
      Select
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();
    
    const { callCmsAdmin } = useCmsAdmin(props, context);
    const { sites, selectedSite, siteAdminConfigurationForm, updateSiteAdminConfiguration, updateSiteAdminConfigurationFormForUpdate } = useSiteAdmin(props, context);
    
    const { site } = toRefs(props);

    var laddaSubmit:Ladda.LaddaButton|null = null;

    const labels = {
      "listComponents" : {
        "fr" : "Composants",
        "en" : "Components"
      },
      "listComponents_placeholder" : {
        "fr" : "Sélectionnez les composants",
        "en" : "Select components"
      },
      "updateButton" : {
        "fr" : "Modifier",
        "en" : "Update"
      }
    }

    onMounted(() => {
      var button:HTMLButtonElement|null = document.querySelector( 'form[name=site-admin-configuration-form] button' );
      laddaSubmit = Ladda.create(button!);
    })

    updateSiteAdminConfigurationFormForUpdate(site.value);

    const listComponents:Ref<string[]> = ref([]);
    const updateComponentsList = async () => {
      // We need to get the list of available companies for the current logged in user
      var response = await callCmsAdmin('/site/'+site.value._id+'/admin-configuration/list-components');
      if(response.componentPaths) {  
        listComponents.value = response.componentPaths;
      }
    }
    updateComponentsList();

    const availableComponents = computed(() => {
      return listComponents.value.filter((componentPath:any) => {
        if(!siteAdminConfigurationForm.allowedComponents) { return true; }
        else {
          return siteAdminConfigurationForm.allowedComponents.indexOf(componentPath) < 0
        }
      })
    });

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();
      updateSiteAdminConfiguration().then((result:any) => {
        if(result.updated) {
          context.emit('site-updated', result.site);
        }
        laddaSubmit!.stop();
      })
    }

    // We watch if we have site ID in URL
    watch(
      site,
      (val:any, oldVal:any) => {
        updateSiteAdminConfigurationFormForUpdate(site.value);
      },
      { deep: true }
    )

    return {
      onFormSubmit,
      siteAdminConfigurationForm,
      availableComponents,
      labels
    }
  }
})
</script>